<template>
  <div
    class="CardItem pb-4 w-full md:w-1/3 flex flex-col items-stretch flex-grow underline-animation-border relative cursor-pointer"
  >
    <UiCustomLink :path="path">
      <!-- Todo : Push real news info and real news image -->
      <!-- Todo : Create image style with 270px height and remove h-270px class -->
      <div class="overflow-hidden rounded lg:max-h-[270px]">
        <UiCustomImage
            :src="image"
            presets="team_actuality"
            customClass="object-cover w-full h-full rounded bg-grisClair"
        />
      </div>
      <p
        class="font-lora text-lg font-medium leading-[16px] py-4 text-orangeCurie"
      >
        {{ tag }}
      </p>
      <h3 class="text-xl mt-0 font-normal leading-6 font-lora overflow-hidden">
        {{ title }}
      </h3>
      <p class="font-raleway text-sm leading-[18px] mt-auto pt-4">
        {{ date }}
      </p>
    </UiCustomLink>
  </div>
</template>

<script>
export default {
  name: "ActualityCard",
  props: {
    image: {
      type: String,
      required: true,
    },
    tag: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
  },
};
</script>
<style scoped>
.CardItem img {
  transition: all 0.6s ease;
}

.CardItem:hover img {
  transform: scale(1.2);
}
</style>
