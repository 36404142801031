<template>
  <div class="w-full">
    <div class="relative w-full">
      <button
        @click="toggleDropdown"
        class="inline-flex justify-between items-center p-3 px-6 w-full text-lg font-normal bg-white border border-grisClair text-grisFont font-raleway"
        type="button"
        :class="isDropdownOpen ? 'rounded-tr rounded-tl' : 'rounded'"
      >
        {{ $t("cancerType") }}
        <nuxt-icon v-show="!isDropdownOpen" name="chevronDown" filled />
        <nuxt-icon v-show="isDropdownOpen" name="chevronUp" filled />
      </button>
      <!-- Dropdown menu -->
      <div
        v-show="isDropdownOpen"
        id="dropdown"
        class="absolute z-20 w-full bg-white rounded-br rounded-bl border border-grisClair border-t-0"
        :class="isFlex ? 'top-[54px]' : ''"
      >
        <ul class="px-6 pb-5 text-sm text-gray-700 dark:text-gray-200">
          <li v-for="item in listItems"
            class="block pt-6 font-normal cursor-pointer text-regularMobile lg:text-regular text-grisFont font-raleway hover:text-orangeCurie hover:font-bold"
          >
            <UiCustomLink
              :path="item.uri.path">
                {{ item.title }}
            </UiCustomLink>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
interface ListItem {
  title?: string;
  uri?: {
    path?: string;
  };
}

const props = defineProps<{
  listItems: ListItem[];
}>();

const isDropdownOpen = ref(false);

const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
};
</script>
