import type { FieldMetatag } from "~/interfaces/global";
import type { SeoTags } from "~/interfaces/seo";

export default function useSeo() {
  const config = useRuntimeConfig();

  const formatSeoTag = (type: "title" | "description", seoTag?: string, defaultValue?: string) => {
    const { t } = useI18n();

    if (!seoTag) {
      if(type === "description") {
        let formattedTag = t('meta.find') + " " + defaultValue + ". " + t('meta.desc');
        return formattedTag;
      }
      else {
        return "";
      }
    }

    let formattedTag = stripHtml(seoTag);
    if (type === "title") {
      formattedTag += " - Institut Curie";
      //return formattedTag.length > 60 ? formattedTag.slice(0, 57) + "..." : formattedTag;   #CURIEPROD-751
      return formattedTag;
    }

    //return formattedTag.length > 155 ? formattedTag.slice(0, 152) + "..." : formattedTag;      #CURIEPROD-751
    return formattedTag;
  };

  const getBackendMetatags = (backendMetatags: FieldMetatag | null) => {
    if (backendMetatags?.value) {
      return JSON.parse(stripHtml(backendMetatags.value));
    }

    return null;
  };

  const setMetaTags = (seoTags: SeoTags) => {
    if (seoTags?.canonical_url) {
      useHead({
        link: [{ rel: "canonical", href: seoTags.canonical_url }],
      });
    }



    useSeoMeta({
      title: seoTags.title,
      ogTitle: seoTags.title,
      ogType: "website",
      ogSiteName: "Institut Curie",
      ogImage: config.public.frontendHost + "/_nuxt/assets/img/menu/logo-color.svg",
      ...(seoTags.description && { description: seoTags.description, ogDescription: seoTags.description }),
      ...(seoTags.canonical_url && { ogUrl: seoTags.canonical_url }),
      ...(seoTags.author && { author: seoTags.author }),
      ...(seoTags.generator && { generator: seoTags.generator }),
      ...(seoTags.google && { googlebot: seoTags.google }),
      ...(seoTags.image_src && { ogImageUrl: seoTags.image_src }),
      ...(seoTags.keywords && { keywords: seoTags.keywords }),
      ...(seoTags.rating && { rating: seoTags.rating }),
      ...(seoTags.referrer && { referrer: seoTags.referrer }),
      ...(seoTags.refresh && { refresh: seoTags.refresh }),
      ...(seoTags.robots && { robots: seoTags.robots }),
    })
  };

  return { setMetaTags, getBackendMetatags, formatSeoTag };
}
