import {  useNuxtApp, useRuntimeConfig } from "#imports";
import type { TeamItems } from "~/interfaces/team";

export const getDefaultPageTags=():Array<string> => {
    const config = useRuntimeConfig();
    return [
        `menu:NAVIGATION_MAIN_${config.public.language}`, 
        `menu:NAVIGATION_SECONDARY_${config.public.language}`,
        `FOOTER_MAIN_${config.public.language}`,
        `FOOTER_SECONDARY_${config.public.language}`,
        `menu:NAVIGATION_SEARCH_${config.public.language}`
    ];
}


export const getTeamCacheTag = (team:TeamItems) :string[] => {
    const tags :string[] = [];
    tags.push(`page:${team.entityById.id}`);
    if(team.entityById?.fieldTeamType){
        tags.push(...team.entityById?.fieldTeamType.map((teamType)=>{return `taxonomy:${teamType.id}`}));
    }
    if(team.entityById?.fieldPartners){
        tags.push(...team.entityById?.fieldPartners.map((partner)=>{return `taxonomy:${partner.id}`}));
    }
    if(team.entityById?.fieldOtherPartners){
        tags.push(...team.entityById?.fieldOtherPartners.map((partner)=>{return `taxonomy:${partner.id}`}));
    }
    if(team.entityById?.fieldSites){
        tags.push(...team.entityById?.fieldSites.map((site)=>{return `taxonomy:${site.id}`}));
    }
    if(team.entityById?.fieldScientificAxis){
        tags.push(...team.entityById?.fieldScientificAxis.map((site)=>{return `taxonomy:${site.id}`}));
    }
    if(team.entityById?.computedTeamRelatedUnit){
        tags.push(...team.entityById?.computedTeamRelatedUnit.map((unit)=>{return `page:${unit.id}`}));
    }
    if(team.entityById?.computedTeamRelatedUnit){
        tags.push(...team.entityById?.computedTeamRelatedUnit.map((unit)=>{return `page:${unit.id}`}));
    }
    if(team.entityById?.fieldTeamMember){
        tags.push(...team.entityById?.fieldTeamMember.map((member)=>{return `page:${member.fieldPerson?.id}`}));
    }
    if(team.entityById?.fieldTeamMember){
        tags.push(...team.entityById?.fieldTeamMember.map((member)=>{return `taxonomy:${member.fieldFunction?.id}`}));
    }
    if(team.entityById?.fieldTeamTab1){
        tags.push(...team.entityById?.fieldTeamTab1.map((tab)=>{
            return tab.fieldTeamParagraphs?.map((paragraph) => {
                return `block_content:${paragraph.id}`
            })
        }));
    }
    if(team.entityById?.fieldTeamTab2){
        tags.push(...team.entityById?.fieldTeamTab2.map((tab)=>{
            return tab.fieldTeamParagraphs?.map((paragraph) => {
                return `block_content:${paragraph.id}`
            })
        }));
    }

    if(team.entityById?.fieldTeamTab3){
        tags.push(...team.entityById?.fieldTeamTab3.map((tab)=>{
            return tab.fieldTeamParagraphs?.map((paragraph) => {
                return `block_content:${paragraph.id}`
            })
        }));
    }
   
    if(team.entityById?.fieldPublications){
        tags.push(...team.entityById?.fieldPublications.map((pubication)=>{return `page:${pubication.id}`}));
    }
    if(team.entityById?.computedNewsTeam){
        tags.push(...team.entityById?.computedNewsTeam.map((team)=>{return `page:${team.id}`}));
    }
    if(team.entityById?.computedTeamUnitRelatedJob){
        tags.push(...team.entityById?.computedTeamUnitRelatedJob.map((job)=>{return `page:${job.id}`}));
    }
    if(team.entityById?.fieldFooter){
        tags.push(...team.entityById?.fieldFooter.map((paragraph)=>{return `block_content:${paragraph.fieldParagraph.id}`}));
    }
    return tags;
}